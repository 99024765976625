.sidebarOpen {
    .sidebarContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 480px;
        height: 100vh;
        margin: 0;
        overflow-y: auto;
        
        @media ( max-width: 1000px ) { width: 360px; }
        @media ( max-width: 860px ) { width: 100vw; }
    }

    nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height: 100%;
        position: fixed;
    }

    ul {
        height: 320px;
        font-family: "Dela Gothic One", sans-serif;
        font-size: 48px;
        list-style-type: none;
        padding-inline-start: 0;
    }

    .navSpacing {
        margin-top: 60px;
    }

    a {
        text-decoration: none;
        color: inherit;
    }
}

button {
    width: 60px;
    height: 60px;
    position: fixed;
    top: 32px;
}

.closeSidebarButton {
    left: 388px;

    @media ( max-width: 1000px ) { left: 268px; }

    @media ( max-width: 860px ) {
            right: 32px;
            left: unset;
    }
}



.openSidebarButton {
    left: 32px;
}

.redStyle {
    color: #911111;
    background-color: #F29EAC;

    .currentPage {
        color: #9307A2;
    }
}

.tealStyle {
    color: #0D4647;
    background-color: #FAF5D8;

    .currentPage {
        color: #08997C
    }
}

.purpleStyle {
    color: #6A32B3;
    background-color: #FFD8B3;

    .currentPage {
        color: #C42B9E;
    }
}

.blueStyle {
    color: #101E6D;
    background-color: #BD6AE3;

    .currentPage {
        color: #FFFB01;
    }
}

.greenStyle {
    color: #2F5E37;
    background-color: #B9FBEE;

    .currentPage {
        color: #0068F1;
    }
}

.orangeStyle {
    color: #CB5800;
    background-color: #F3DB99;

    .currentPage {
        color: #7E61FF;
    }
}

.defaultSidebar {
    color: #2D353B;
    background-color: #6F777C;

    .currentPage {
        color: #FFFFFF;
    }
}