.aboutContainer {
  display: flex;
  background-color: #f9f8f7;
}

.aboutContent {
  display: flex;
  flex-direction: column;
  width: 100vw;
  align-items: center;

  .footerContainer {
    @media ( max-height: 1500px ) { margin-top: 500px; }
    
    @media ( max-height: 1200px ) { margin-top: 200px; }

    @media ( max-height: 900px ) { margin-top: 92px; }
  }

  h2 {
    font-family: "Dela Gothic One", sans-serif;
    font-size: 96px;
    color: #393232;
    margin: 80px 32px 32px 32px;

    @media ( max-width: 1000px ) { font-size: 64px; }
  }

  h3 {
    font-family: "Libre Franklin", sans-serif;
    font-size: 40px;
    color: #6f777c;
    margin: 0 32px 32px;

    @media ( max-width: 1000px ) { font-size: 32px; }
  }

  p {
    font-family: "Libre Franklin", sans-serif;
    font-size: 24px;
    color: #6f777c;
    margin: 16px 32px 32px 32px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: unset;
  }

  .emailStyle {
    color: #393232;
  }
}